<template>
    <a-modal v-model="visible" title="回复管理" :width="1000" @ok="visible=false" center>
        <common-table
                ref="table"
                path="web/app-forum-reply"
                :query-params="queryParams"

                :columns="columns">

            <template slot="search">
                <a-button ghost type="primary" @click="add">添加评论</a-button>
            </template>

            <template slot="logo" slot-scope="{record}">
                <a-avatar shape="square" :size="30" :src="record.avatarUrl">{{record.nickName}}</a-avatar>
            </template>

            <template slot="operation" slot-scope="{record}">
                <!--        <action-edit @click="edit(record)"></action-edit>-->
                <a-button ghost type="primary" size="small" @click="add" style="margin-right: 8px">回复管理</a-button>
                <action-delete @click="remove(record)"></action-delete>
            </template>

        </common-table>
    </a-modal>
</template>

<script>
    export default {
        data(){
            return {
                visible:false,
                queryParams:{
                    forumPostId:"",
                    parentId:""
                }
            }
        },
        computed:{
            columns () {
                return [
                    {
                        title: '用户头像',
                        dataIndex: 'avatarUrl',
                        scopedSlots: {customRender: 'logo'},
                    },
                    {
                        title: '用户',
                        dataIndex: 'nickName',
                    },
                    {
                        title: '回复内容',
                        dataIndex: 'content',
                    },
                    {
                        title: '时间',
                        dataIndex: 'createdAt'
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        scopedSlots: {customRender: 'operation'},
                    }
                ]
            }
        },
        methods:{
            show(forumPostId,replyId){
                this.visible=true
                this.queryParams.forumPostId = forumPostId
                this.queryParams.parentId = replyId
                this.getList()
            },
            remove (record) {
                var that = this
                this.$confirm({
                    title: '是否删除该回复?',
                    content: '该操作不可撤销',
                    centered: true,
                    onOk () {
                        that.$delete('web/app-forum-reply/' + record.id).then(() => {
                            that.$message.success('删除成功')
                            that.getList()
                        })
                    }
                })
            },
            getList () {
                this.$refs.table.getData()
            },
        },

    }
</script>

<style scoped>

</style>
